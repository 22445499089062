import React from "react";
import { Field, ErrorMessage, useField, useFormikContext } from "formik";

interface CheckboxFieldProps {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
  showError?: boolean;
  onValueChange?: (checked: boolean) => void; // Custom handler for value change
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  id,
  name,
  label,
  showError = true,
  onValueChange,
}) => {
  const [field, meta] = useField({ name, type: "checkbox" });
  const { setFieldValue } = useFormikContext();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event?.target?.checked;
    // field.onChange(event); // Call Formik's internal handler
    setFieldValue(name, checked);
    if (onValueChange) {
      onValueChange(checked); // Trigger custom change handler
    }
  };

  return (
    <>
    <Field type="checkbox" {...field} onChange={handleChange} />
      <div className="form-checkbox__mark">
        <div className="form-checkbox__icon icon-check" />
      </div>
      {/* {label && <label htmlFor={id || name} className="lh-1 text-12 text-light-1">{label}</label>} */}
      {/* Display error if present */}
      {showError && (
        <ErrorMessage name={name}>
          {(msg) => <span className="error-message">{msg}</span>}
        </ErrorMessage>
      )}
    </>
  );
};
