"use client";
import React from 'react';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import Select from 'react-select';

interface SelectFieldProps {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
  parentClassName?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onValueChange?: (value: string | null) => void; // Custom handler for value change
}

export const ReactSelectField: React.FC<SelectFieldProps> = ({
  name,
  options,
  label,
  parentClassName = '',
  onValueChange,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Function to handle value changes
  const handleChange = (selectedOption: any) => {
    const value = selectedOption ? selectedOption.value : '';
    setFieldValue(name, value); // Set formik value
    if (onValueChange) {
      onValueChange(value); // Custom handler
    }
  };

  // Convert options to react-select format
  const reactSelectOptions = options.map(option => ({
    value: option.value,
    label: option.label,
  }));

  return (
    <div className={`form-select-override ${parentClassName}`}>
      <Select
        id={name}
        name={name}
        options={reactSelectOptions}
        value={reactSelectOptions.find(option => option?.value === field?.value)}
        onChange={handleChange}
        onBlur={() => field.onBlur(name)}
        classNamePrefix="select"
        placeholder={label || 'Select'}
        {...rest}
      />
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};
