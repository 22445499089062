import React from "react";
import { ArrowClockwise } from "react-bootstrap-icons";
import styles from "./Loader.module.scss";
import LOGO_LIGHT from "@/img/general/logo-light.svg";

interface LoaderProps {
  show: boolean;
  content?: any;
}

export const Loader: React.FC<LoaderProps> = ({ show, content }) => {
  return (
    <>
      {show && (
        <div className={styles?.loader} suppressHydrationWarning>
          <div className={styles.loaderContent}>
            <img
              src={LOGO_LIGHT?.src}
              alt="Logo"
              className={styles?.loaderLogo}
            />
            {content && content !== "" && (
              <div className={styles?.loaderText}>{content}</div>
            )}
            <ArrowClockwise className={styles?.spinner} />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
