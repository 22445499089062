export const currencies = [
    {
      id: 1,
      code: "USD",
      name: "US Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "US",
      show: true,
      min: 0,
      max: 700,
      step: 10
    },
    {
      id: 2,
      code: "PLN",
      name: "Zloty",
      symbol: "zł",
      html_entity: "&#1083;&#1074;",
      nationality_code: "PL",
    },
    {
      id: 3,
      code: "JPY",
      name: "Yen",
      symbol: "¥",
      html_entity: "&#165;",
      nationality_code: "JP",
    },
    {
      id: 4,
      code: "KRW",
      name: "Won",
      symbol: "₩",
      html_entity: "&#8361;",
      nationality_code: "KR",
    },
    {
      id: 5,
      code: "VUV",
      name: "Vatu",
      symbol: "Vt",
      html_entity: "Vt",
      nationality_code: "VU",
    },
    {
      id: 6,
      code: "MNT",
      name: "Tugrik",
      symbol: "₮",
      html_entity: "&#8366;",
      nationality_code: "MN",
    },
    {
      id: 7,
      code: "KZT",
      name: "Tenge",
      symbol: "₸",
      html_entity: "&#8376;",
      nationality_code: "KZ",
    },
    {
      id: 8,
      code: "WST",
      name: "Tala",
      symbol: "T",
      html_entity: "T",
      nationality_code: "WS",
    },
    {
      id: 9,
      code: "BDT",
      name: "Taka",
      symbol: "৳",
      html_entity: "&#2547;",
      nationality_code: "BD",
    },
    {
      id: 10,
      code: "UZS",
      name: "Uzbekistan Sum",
      symbol: "сўм",
      html_entity: "&#1089;&#1091;&#1084;",
      nationality_code: "UZ",
    },
    {
      id: 11,
      code: "TJS",
      name: "Somoni",
      symbol: "ЅМ",
      html_entity: "ЅМ",
      nationality_code: "TJ",
    },
    {
      id: 12,
      code: "KGS",
      name: "Som",
      symbol: "лв",
      html_entity: "&#1083;&#1074;",
      nationality_code: "KG",
    },
    {
      id: 13,
      code: "PEN",
      name: "Sol",
      symbol: "S/",
      html_entity: "S/",
      nationality_code: "PE",
    },
    {
      id: 14,
      code: "UGX",
      name: "Uganda Shilling",
      symbol: "Sh",
      html_entity: "Sh",
      nationality_code: "UG",
    },
    {
      id: 15,
      code: "TZS",
      name: "Tanzanian Shilling",
      symbol: "Sh",
      html_entity: "Sh",
      nationality_code: "TZ",
    },
    {
      id: 16,
      code: "SOS",
      name: "Somali Shilling",
      symbol: "Sh",
      html_entity: "Sh",
      nationality_code: "SO",
    },
    {
      id: 17,
      code: "IDR",
      name: "Rupiah",
      symbol: "Rp",
      html_entity: "Rp",
      nationality_code: "ID",
    },
    {
      id: 18,
      code: "SCR",
      name: "Seychelles Rupee",
      symbol: "₨",
      html_entity: "&#8360;",
      nationality_code: "SC",
    },
    {
      id: 19,
      code: "MVR",
      name: "Rufiyaa",
      symbol: "Rf",
      html_entity: "Rf",
      nationality_code: "MV",
    },
    {
      id: 20,
      code: "RUB",
      name: "Russian Ruble",
      symbol: "₽",
      html_entity: "&#8381;",
      nationality_code: "RU",
    },
    {
      id: 21,
      code: "SAR",
      name: "Saudi Riyal",
      symbol: "﷼",
      html_entity: "&#65020;",
      nationality_code: "SA",
    },
    {
      id: 22,
      code: "KHR",
      name: "Riel",
      symbol: "៛",
      html_entity: "&#6107;",
      nationality_code: "KH",
    },
    {
      id: 23,
      code: "YER",
      name: "Yemeni Rial",
      symbol: "﷼",
      html_entity: "&#65020;",
      nationality_code: "YE",
    },
    {
      id: 24,
      code: "CNY",
      name: "Yuan Renminbi",
      symbol: "¥",
      html_entity: "&#165;",
      nationality_code: "CN",
    },
    {
      id: 25,
      code: "ZAR",
      name: "Rand",
      symbol: "R",
      html_entity: "R",
      nationality_code: "ZA",
    },
    {
      id: 26,
      code: "GTQ",
      name: "Quetzal",
      symbol: "Q",
      html_entity: "Q",
      nationality_code: "GT",
    },
    {
      id: 27,
      code: "QAR",
      name: "Qatari Rial",
      symbol: "﷼",
      html_entity: "&#65020;",
      nationality_code: "QA",
    },
    {
      id: 28,
      code: "BWP",
      name: "Pula",
      symbol: "P",
      html_entity: "P",
      nationality_code: "BW",
    },
    {
      id: 29,
      code: "SYP",
      name: "Syrian Pound",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "SY",
    },
    {
      id: 30,
      code: "SSP",
      name: "South Sudanese Pound",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "SS",
    },
    {
      id: 31,
      code: "SDG",
      name: "Sudanese Pound",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "SD",
    },
    {
      id: 32,
      code: "GBP",
      name: "Pound Sterling",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "GB",
      show: true,
      min:0,
      max: 700,
      step: 10
    },
    {
      id: 33,
      code: "UYU",
      name: "Peso Uruguayo",
      symbol: "$U",
      html_entity: "&#36;U",
      nationality_code: "UY",
    },
    {
      id: 34,
      code: "PHP",
      name: "Philippine Peso",
      symbol: "₱",
      html_entity: "&#8369;",
      nationality_code: "PH",
    },
    {
      id: 35,
      code: "MOP",
      name: "Pataca",
      symbol: "P",
      html_entity: "P",
      nationality_code: "MO",
    },
    {
      id: 36,
      code: "PKR",
      name: "Pakistan Rupee",
      symbol: "₨",
      html_entity: "&#8360;",
      nationality_code: "PK",
    },
    {
      id: 37,
      code: "TOP",
      name: "Pa'anga",
      symbol: "T$",
      html_entity: "T&#36;",
      nationality_code: "TO",
    },
    {
      id: 38,
      code: "MRO",
      name: "Ouguiya",
      symbol: "UM",
      html_entity: "UM",
      nationality_code: "MR",
    },
    {
      id: 39,
      code: "OMR",
      name: "Rial Omani",
      symbol: "﷼",
      html_entity: "&#65020;",
      nationality_code: "OM",
    },
    {
      id: 40,
      code: "BTN",
      name: "Ngultrum",
      symbol: "Nu.",
      html_entity: "Nu.",
      nationality_code: "BT",
    },
    {
      id: 41,
      code: "NPR",
      name: "Nepalese Rupee",
      symbol: "₨",
      html_entity: "&#8360;",
      nationality_code: "NP",
    },
    {
      id: 42,
      code: "ERN",
      name: "Nakfa",
      symbol: "Nkf",
      html_entity: "Nkf",
      nationality_code: "ER",
    },
    {
      id: 43,
      code: "NGN",
      name: "Naira",
      symbol: "₦",
      html_entity: "&#8358;",
      nationality_code: "NG",
    },
    {
      id: 44,
      code: "BOV",
      name: "Mvdol",
      symbol: "Mv",
      html_entity: "Mv",
      nationality_code: "BO",
    },
    {
      id: 45,
      code: "MXN",
      name: "Mexican Peso",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "MX",
      show: true,
      min:0,
      max: 60000,
      step: 100
    },
    {
      id: 46,
      code: "MZN",
      name: "Mozambique Metical",
      symbol: "MT",
      html_entity: "MT",
      nationality_code: "MZ",
    },
    {
      id: 47,
      code: "MUR",
      name: "Mauritius Rupee",
      symbol: "₨",
      html_entity: "&#8360;",
      nationality_code: "MU",
    },
    {
      id: 48,
      code: "TMT",
      name: "Turkmenistan New Manat",
      symbol: "m",
      html_entity: "m",
      nationality_code: "TM",
    },
    {
      id: 49,
      code: "MYR",
      name: "Malaysian Ringgit",
      symbol: "RM",
      html_entity: "RM",
      nationality_code: "MY",
    },
    {
      id: 50,
      code: "LSL",
      name: "Loti",
      symbol: "L",
      html_entity: "L",
      nationality_code: "LS",
    },
    {
      id: 51,
      code: "TRY",
      name: "Turkish Lira",
      symbol: "₺",
      html_entity: "&#8378;",
      nationality_code: "TR",
    },
    {
      id: 52,
      code: "SZL",
      name: "Lilangeni",
      symbol: "L",
      html_entity: "L",
      nationality_code: "SZ",
    },
    {
      id: 53,
      code: "RON",
      name: "Romanian Leu",
      symbol: "lei",
      html_entity: "lei",
      nationality_code: "RO",
    },
    {
      id: 54,
      code: "MDL",
      name: "Moldovan Leu",
      symbol: "L",
      html_entity: "L",
      nationality_code: "MD",
    },
    {
      id: 55,
      code: "SLL",
      name: "Leone",
      symbol: "L",
      html_entity: "L",
      nationality_code: "SL",
    },
    {
      id: 56,
      code: "HNL",
      name: "Lempira",
      symbol: "L",
      html_entity: "L",
      nationality_code: "HN",
    },
    {
      id: 57,
      code: "ALL",
      name: "Lek",
      symbol: "L",
      html_entity: "L",
      nationality_code: "AL",
    },
    {
      id: 58,
      code: "LBP",
      name: "Lebanese Pound",
      symbol: "ل.ل",
      html_entity: "&#1604;&#1604;",
      nationality_code: "LB",
    },
    {
      id: 59,
      code: "GEL",
      name: "Lari",
      symbol: "₾",
      html_entity: "&#8382;",
      nationality_code: "GE",
    },
    {
      id: 60,
      code: "LKR",
      name: "Sri Lanka Rupee",
      symbol: "₨",
      html_entity: "&#8360;",
      nationality_code: "LK",
    },
    {
      id: 61,
      code: "MMK",
      name: "Kyat",
      symbol: "K",
      html_entity: "K",
      nationality_code: "MM",
    },
    {
      id: 62,
      code: "AOA",
      name: "Kwanza",
      symbol: "Kz",
      html_entity: "Kz",
      nationality_code: "AO",
    },
    {
      id: 63,
      code: "ZMW",
      name: "Zambian Kwacha",
      symbol: "ZK",
      html_entity: "ZK",
      nationality_code: "ZM",
    },
    {
      id: 64,
      code: "MWK",
      name: "Malawi Kwacha",
      symbol: "MK",
      html_entity: "MK",
      nationality_code: "MW",
    },
    {
      id: 65,
      code: "HRK",
      name: "Kuna",
      symbol: "kn",
      html_entity: "kn",
      nationality_code: "HR",
    },
    {
      id: 66,
      code: "NOK",
      name: "Norwegian Krone",
      symbol: "kr",
      html_entity: "kr",
      nationality_code: "NO",
    },
    {
      id: 67,
      code: "SEK",
      name: "Swedish Krona",
      symbol: "kr",
      html_entity: "kr",
      nationality_code: "SE",
    },
    {
      id: 68,
      code: "KPW",
      name: "North Korean Won",
      symbol: "₩",
      html_entity: "&#8361;",
      nationality_code: "KP",
    },
    {
      id: 69,
      code: "LAK",
      name: "Kip",
      symbol: "₭",
      html_entity: "&#8365;",
      nationality_code: "LA",
    },
    {
      id: 70,
      code: "PGK",
      name: "Kina",
      symbol: "K",
      html_entity: "K",
      nationality_code: "PG",
    },
    {
      id: 71,
      code: "KES",
      name: "Kenyan Shilling",
      symbol: "Sh",
      html_entity: "Sh",
      nationality_code: "KE",
    },
    {
      id: 72,
      code: "ILS",
      name: "New Israeli Sheqel",
      symbol: "₪",
      html_entity: "&#8362;",
      nationality_code: "IL",
    },
    {
      id: 73,
      code: "IRR",
      name: "Iranian Rial",
      symbol: "﷼",
      html_entity: "&#65020;",
      nationality_code: "IR",
    },
    {
      id: 74,
      code: "INR",
      name: "Indian Rupee",
      symbol: "₹",
      html_entity: "&#8377;",
      nationality_code: "IN",
      show: true,
      min:0,
      max: 60000,
      step: 100
    },
    {
      id: 75,
      code: "ISK",
      name: "Iceland Krona",
      symbol: "kr",
      html_entity: "kr",
      nationality_code: "IS",
    },
    {
      id: 76,
      code: "UAH",
      name: "Hryvnia",
      symbol: "₴",
      html_entity: "&#8372;",
      nationality_code: "UA",
    },
    {
      id: 77,
      code: "SHP",
      name: "Saint Helena Pound",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "SH",
    },
    {
      id: 78,
      code: "PYG",
      name: "Guarani",
      symbol: "₲",
      html_entity: "&#8370;",
      nationality_code: "PY",
    },
    {
      id: 79,
      code: "HTG",
      name: "Gourde",
      symbol: "G",
      html_entity: "G",
      nationality_code: "HT",
    },
    {
      id: 80,
      code: "GIP",
      name: "Gibraltar Pound",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "GI",
    },
    {
      id: 81,
      code: "RWF",
      name: "Rwanda Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "RW",
    },
    {
      id: 82,
      code: "GNF",
      name: "Guinea Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "GN",
    },
    {
      id: 83,
      code: "CHF",
      name: "Swiss Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "CH",
    },
    {
      id: 84,
      code: "HUF",
      name: "Forint",
      symbol: "Ft",
      html_entity: "Ft",
      nationality_code: "HU",
    },
    {
      id: 85,
      code: "FKP",
      name: "Falkland Islands Pound",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "FK",
    },
    {
      id: 86,
      code: "EUR",
      name: "Euro",
      symbol: "€",
      html_entity: "&#8364;",
      nationality_code: "EU",
      show: false,
      min: 0,
      max: 700,
      step: 10,
    },
    {
      id: 87,
      code: "EGP",
      name: "Egyptian Pound",
      symbol: "£",
      html_entity: "&#163;",
      nationality_code: "EG",
    },
    {
      id: 88,
      code: "VND",
      name: "Dong",
      symbol: "₫",
      html_entity: "&#8363;",
      nationality_code: "VN",
    },
    {
      id: 89,
      code: "DOP",
      name: "Dominican Peso",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "DO",
    },
    {
      id: 90,
      code: "ZWL",
      name: "Zimbabwe Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "ZW",
    },

    {
      id: 91,
      code: "TWD",
      name: "New Taiwan Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "TW",
    },
    {
      id: 92,
      code: "SRD",
      name: "Surinam Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "SR",
    },
    {
      id: 93,
      code: "SGD",
      name: "Singapore Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "SG",
    },
    {
      id: 94,
      code: "SBD",
      name: "Solomon Islands Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "SB",
    },
    {
      id: 95,
      code: "NZD",
      name: "New Zealand Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "NZ",
    },
    {
      id: 96,
      code: "NAD",
      name: "Namibia Dollar",
      symbol: "R",
      html_entity: "R",
      nationality_code: "NA",
    },
    {
      id: 97,
      code: "LRD",
      name: "Liberian Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "LR",
    },
    {
      id: 98,
      code: "JMD",
      name: "Jamaican Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "JM",
    },
    {
      id: 99,
      code: "HKD",
      name: "Hong Kong Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "HK",
    },
    {
      id: 100,
      code: "GYD",
      name: "Guyana Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "GY",
    },
    {
      id: 101,
      code: "FJD",
      name: "Fiji Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "FJ",
    },
    {
      id: 102,
      code: "STD",
      name: "Dobra",
      symbol: "Db",
      html_entity: "Db",
      nationality_code: "ST",
    },
    {
      id: 103,
      code: "DJF",
      name: "Djibouti Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "DJ",
    },
    {
      id: 104,
      code: "MAD",
      name: "Moroccan Dirham",
      symbol: "د.م.",
      html_entity: "&#1583;&#1605;",
      nationality_code: "MA",
    },
    {
      id: 105,
      code: "AED",
      name: "UAE Dirham",
      symbol: "د.إ",
      html_entity: "&#1583;&#1573;",
      nationality_code: "AE",
      show: true,
      min: 0,
      max: 3000,
      step: 100
    },
    {
      id: 106,
      code: "TND",
      name: "Tunisian Dinar",
      symbol: "د.ت",
      html_entity: "&#1583;&#1578;",
      nationality_code: "TN",
    },
    {
      id: 107,
      code: "RSD",
      name: "Serbian Dinar",
      symbol: "дин",
      html_entity: "&#1076;&#1080;&#1085;",
      nationality_code: "RS",
    },
    {
      id: 108,
      code: "LYD",
      name: "Libyan Dinar",
      symbol: "ل.د",
      html_entity: "&#1604;&#1583;",
      nationality_code: "LY",
    },
    {
      id: 109,
      code: "KWD",
      name: "Kuwaiti Dinar",
      symbol: "د.ك",
      html_entity: "&#1583;&#1603;",
      nationality_code: "KW",
    },
    {
      id: 110,
      code: "JOD",
      name: "Jordanian Dinar",
      symbol: "د.ا",
      html_entity: "&#1583;&#1575;",
      nationality_code: "JO",
    },
    {
      id: 111,
      code: "IQD",
      name: "Iraqi Dinar",
      symbol: "ع.د",
      html_entity: "&#1593;&#1583;",
      nationality_code: "IQ",
    },
    {
      id: 112,
      code: "MKD",
      name: "Denar",
      symbol: "د.م.",
      html_entity: "&#1583;&#1605;",
      nationality_code: "MK",
    },
    {
      id: 113,
      code: "DKK",
      name: "Danish Krone",
      symbol: "kr",
      html_entity: "kr",
      nationality_code: "DK",
    },
    {
      id: 114,
      code: "GMD",
      name: "Dalasi",
      symbol: "D",
      html_entity: "D",
      nationality_code: "GM",
    },
    {
      id: 115,
      code: "CZK",
      name: "Czech Koruna",
      symbol: "Kč",
      html_entity: "Kč",
      nationality_code: "CZ",
    },
    {
      id: 116,
      code: "NIO",
      name: "Cordoba Oro",
      symbol: "C$",
      html_entity: "C&#36;",
      nationality_code: "NI",
    },
    {
      id: 117,
      code: "CUC",
      name: "Peso Convertible",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "CU",
    },
    {
      id: 118,
      code: "BAM",
      name: "Convertible Mark",
      symbol: "KM",
      html_entity: "KM",
      nationality_code: "BA",
    },
    {
      id: 119,
      code: "CDF",
      name: "Congolese Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "CD",
    },
    {
      id: 120,
      code: "KMF",
      name: "Comoro Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "KM",
    },
    {
      id: 121,
      code: "CRC",
      name: "Costa Rican Colon",
      symbol: "₡",
      html_entity: "&#8353;",
      nationality_code: "CR",
    },
    {
      id: 122,
      code: "COP",
      name: "Colombian Peso",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "CO",
    },
    {
      id: 123,
      code: "CLP",
      name: "Chilean Peso",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "CL",
    },
    {
      id: 124,
      code: "XPF",
      name: "CFP Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "PF",
    },
    {
      id: 125,
      code: "GHS",
      name: "Ghana Cedi",
      symbol: "₵",
      html_entity: "&#8373;",
      nationality_code: "GH",
    },
    {
      id: 126,
      code: "KYD",
      name: "Cayman Islands Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "KY",
    },
    {
      id: 127,
      code: "XCD",
      name: "East Caribbean Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "AG",
    },
    {
      id: 128,
      code: "CAD",
      name: "Canadian Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "CA",
      show: true,
      min:0,
      max: 3000,
      step: 10
    },
    {
      id: 129,
      code: "CVE",
      name: "Cabo Verde Escudo",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "CV",
    },
    {
      id: 130,
      code: "BIF",
      name: "Burundi Franc",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "BI",
    },
    {
      id: 131,
      code: "BGN",
      name: "Bulgarian Lev",
      symbol: "лв",
      html_entity: "&#1083;&#1074;",
      nationality_code: "BG",
    },
    {
      id: 132,
      code: "BND",
      name: "Brunei Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "BN",
    },
    {
      id: 133,
      code: "BRL",
      name: "Brazilian Real",
      symbol: "R$",
      html_entity: "&#82;&#36;",
      nationality_code: "BR",
    },
    {
      id: 134,
      code: "VEF",
      name: "Bolivar",
      symbol: "Bs.",
      html_entity: "Bs.",
      nationality_code: "VE",
    },
    {
      id: 135,
      code: "ETB",
      name: "Ethiopian Birr",
      symbol: "Br",
      html_entity: "Br",
      nationality_code: "ET",
    },
    {
      id: 136,
      code: "BMD",
      name: "Bermudian Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "BM",
    },
    {
      id: 137,
      code: "BZD",
      name: "Belize Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "BZ",
    },
    {
      id: 138,
      code: "BYR",
      name: "Belarusian Ruble",
      symbol: "Br",
      html_entity: "Br",
      nationality_code: "BY",
    },
    {
      id: 139,
      code: "XAF",
      name: "CFA Franc BEAC",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "CF",
    },
    {
      id: 140,
      code: "XOF",
      name: "CFA Franc BCEAO",
      symbol: "Fr",
      html_entity: "Fr",
      nationality_code: "BF",
    },
    {
      id: 141,
      code: "BBD",
      name: "Barbados Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "BB",
    },
    {
      id: 142,
      code: "THB",
      name: "Baht",
      symbol: "฿",
      html_entity: "&#3647;",
      nationality_code: "TH",
      show: true,
      min:0,
      max: 30000,
      step: 100
    },
    {
      id: 143,
      code: "BHD",
      name: "Bahraini Dinar",
      symbol: ".د.ب",
      html_entity: "&#1583;&#1576;",
      nationality_code: "BH",
    },
    {
      id: 144,
      code: "BSD",
      name: "Bahamian Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "BS",
    },
    {
      id: 145,
      code: "AZN",
      name: "Azerbaijanian Manat",
      symbol: "₼",
      html_entity: "&#8380;",
      nationality_code: "AZ",
    },
    {
      id: 146,
      code: "AUD",
      name: "Australian Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "AU",
    },
    {
      id: 147,
      code: "AWG",
      name: "Aruban Florin",
      symbol: "Afl.",
      html_entity: "Afl.",
      nationality_code: "AW",
    },
    {
      id: 148,
      code: "AMD",
      name: "Armenian Dram",
      symbol: "֏",
      html_entity: "&#1423;",
      nationality_code: "AM",
    },
    {
      id: 149,
      code: "MGA",
      name: "Malagasy Ariary",
      symbol: "Ar",
      html_entity: "Ar",
      nationality_code: "MG",
    },
    {
      id: 150,
      code: "ARS",
      name: "Argentine Peso",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "AR",
    },
    {
      id: 151,
      code: "ANG",
      name: "Netherlands Antillean Guilder",
      symbol: "ƒ",
      html_entity: "&#402;",
      nationality_code: "AN",
    },
    {
      id: 152,
      code: "TTD",
      name: "Trinidad and Tobago Dollar",
      symbol: "$",
      html_entity: "&#36;",
      nationality_code: "TT",
    },
    {
      id: 153,
      code: "DZD",
      name: "Algerian Dinar",
      symbol: "دج",
      html_entity: "&#1583;&#1580;",
      nationality_code: "DZ",
    },
    {
      id: 154,
      code: "AFN",
      name: "Afghani",
      symbol: "؋",
      html_entity: "&#1547;",
      nationality_code: "AF",
    },
  ];