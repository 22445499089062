import { useMemo } from 'react';

// This file serves as a central hub for re-exporting pre-typed Redux hooks.
import { useDispatch, useSelector, useStore } from "react-redux";
import type { AppDispatch, AppStore, RootState } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();


interface QueryParams {
  params?: any;
  searchParams?:  any;
}

export const useCustomQueryParams = (props: QueryParams, paramKey: string) => {
  const queryParam = useMemo(() => {
    if(paramKey == "query") {
      const data = props?.searchParams?.payload;
      return  { payload: JSON.parse(data)}
    }
    const paramValue = props?.searchParams?.[paramKey];
    if (paramValue) {
      try {
        return JSON.parse(decodeURIComponent(paramValue));
      } catch (error) {
        // console.error(`Failed to parse query parameter "${paramKey}":`, error);
        return null;
      }
    }
    return null;
  }, [props, paramKey]);

  return queryParam;
};
