import { axiosApi, objectToQuery } from "./axios";
import { getSession, signOut } from "next-auth/react"; // for client-side usage
import { getSession as serverSession } from "@/auth"; // import your NextAuth options
import { toast } from "react-toastify";
import { showToast } from "@/app/CustomToastProvider";

const TIMEOUT = 120000; // 80 seconds
const apiBaseUrl = process.env.NEXT_PUBLIC_API_URL;

export const postApi = async (
  formData: any,
  onProgress?: (progress: number) => void
): Promise<any> => {
  const { url, payload, apiUrl } = formData;
  try {
    const response = await axiosApi.post(
      `${apiUrl ?? apiBaseUrl}/${url}`,
      payload,
      {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("percentCompleted", percentCompleted);
            if (onProgress) {
              onProgress(percentCompleted); // Update progress
            }
          }
        },
      }
    );
    if (response?.data?.status && response?.data?.status === 401) {
      showToast("Session expired. Please login again.", "danger");
      signOut({ callbackUrl: "/login" });
    }
    if (onProgress) onProgress(100); // Ensure progress reaches 100% on success
    return response?.data;
  } catch (error: any) {
    console.log("postApi error:", error);
    if (onProgress) onProgress(0); // Reset progress on error
    return error?.response?.data;
  }
};
export const deleteApi = async (formData: any): Promise<any> => {
  const { url, payload, apiUrl } = formData;
  console.log("deleteApi payload", payload);
  try {
    const response = await axiosApi.delete(`${apiUrl ?? apiBaseUrl}/${url}`, {
      data: payload,
    });
    console.log("deleteApi response:", response);
    if (response?.data?.status && response?.data?.status === 401) {
      showToast("Session expired. Please login again.", "danger");
      signOut({ callbackUrl: "/login" });
    }
    return response?.data;
  } catch (error: any) {
    console.log("deleteApi error:", error);
    return error?.response?.data;
  }
};

export const putApi = async (formData: any): Promise<any> => {
  const { url, payload, apiUrl } = formData;
  try {
    const response = await axiosApi.put(
      `${apiUrl ?? apiBaseUrl}/${url}`,
      payload
    );
    console.log("putApi response:", response);
    if (response?.data?.status && response?.data?.status === 401) {
      showToast("Session expired. Please login again.", "danger");
      signOut({ callbackUrl: "/login" });
    }
    return response?.data;
  } catch (error: any) {
    console.log("postApi error:", error);
    return error?.response?.data;
  }
};

export const getApi = async (formData: any): Promise<any> => {
  let queryString = "";
  const { url, payload, apiUrl } = formData;
  if (payload) {
    queryString = objectToQuery(payload);
  }
  try {
    const response = await axiosApi.get(
      `${apiUrl ?? apiBaseUrl}/${url}?${queryString}`
    );
    return response?.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

// Update to support session retrieval in getFetchApi
export const getFetchApi = async (
  formData: any,
  req?: any,
  res?: any,
  onProgress?: (progress: number) => void // Progress callback
): Promise<any> => {
  let session: any = null;

  // For client-side usage
  if (typeof window !== "undefined") {
    session = await getSession();
  }

  // For server-side usage
  if (typeof window === "undefined" && req && res) {
    session = await serverSession();
  }

  // Create timeout controller for fetch
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

  let queryString = "";
  const { url, payload, apiUrl, headers = {} } = formData;
  if (payload) {
    queryString = objectToQuery(payload);
  }
  // Start progress
  let progress = 1; // Start progress
  if (onProgress) onProgress(progress);

  // Simulate smooth progress updates
  const progressInterval = setInterval(() => {
    progress += Math.random() * 10; // Increment randomly (5-10)
    if (progress > 99) clearInterval(progressInterval); // Stop at 90%
    if (onProgress) onProgress(Math.min(progress, 99)); // Limit max progress
  }, 300); //

  try {
    const res = await fetch(`${apiUrl ?? apiBaseUrl}/${url}?${queryString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...(session?.user?.auth_token
          ? { Authorization: `Bearer ${session?.user?.auth_token}` }
          : {}),
        ...headers, // Add custom headers
      },
      signal: controller.signal,
      next: { revalidate: 10 },
    });

    clearTimeout(timeoutId);
    clearInterval(progressInterval); // Stop progress updates

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const result = await res.json();
    if (onProgress) onProgress(100); // Mark as complete
    if (result?.status && result?.status === 401) {
      showToast("Session expired. Please login again.", "danger");
      signOut({ callbackUrl: "/login" });
    }
    return result;
  } catch (error: any) {
    clearInterval(progressInterval); // Stop progress updates on error
    if (onProgress) onProgress(0); // Reset progress on failure

    if (error.name === "AbortError") {
      showToast("Request timed out.", "danger");
      console.log("Request timed out.");
    } else {
      console.error("getFetchApi error:", error);
    }

    throw error;
  }
};

/*export const getFetchApi = async (formData: any, req?: any, res?: any): Promise<any> => {
  let session: any = null;

  // For client-side usage
  if (typeof window && typeof window !== "undefined") {
    session = await getSession();
    // console.log("Client-side session:", session);
  }
  
  // For server-side usage
  if ((!typeof window || typeof window == undefined) && req && res) {
    session = await serverSession();
    // console.log("Server-side session:", session);
  }

  // Create timeout controller for fetch
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

  // Proceed with API call
  let queryString = "";
  const { url, payload, apiUrl, headers = {} } = formData;
  if (payload) {
    queryString = objectToQuery(payload);
  }
  try {
    const res = await fetch(`${apiUrl ?? apiBaseUrl}/${url}?${queryString}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        // Include additional headers here
        ...(session?.user?.auth_token ? { Authorization: `Bearer ${session?.user?.auth_token}` } : {}),
        ...headers, // Add custom headers from formData
      },
      signal: controller.signal,
      next: { revalidate: 10 }, // Optional: revalidation strategy
      
    });
    clearTimeout(timeoutId); // Clear timeout after response
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const result = await res.json();
    console.log("getFetchApi response:", result);

    if(result?.status && result?.status === 401){
      showToast("Session expired. Please login again.","danger");
      signOut({ callbackUrl: '/login' });
    }
    return result;
  } catch (error: any) {
    if (error.name === "AbortError") {
      showToast("Request timed out.","danger");
      console.log("Request timed out.");
    } else {
      console.error("getFetchApi error:", error);
    }
    throw error;
  }
};*/

// Generic fetcher utility
export const fetcher = (url: any) => fetch(url).then((r) => r.json());
