import  axios  from "axios";
import { getSession } from "next-auth/react";
//import { getToken, handleInvalidToken } from './Service';
const axiosApi = axios.create({
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
    //language: `EN`
  },
  timeout: 120000,
});
// doing something with the request
axiosApi.interceptors.request.use(async (request: any) => {
  // console.log("axiosApi.interceptors.request.use", request);
  let session: any = null;

  if (typeof window !== "undefined") {
    session = await getSession();
  }
 
  const token = session?.user?.auth_token; 
  // console.log("token", token);

  // const token = "";
  if (token != "" && token != null && token != undefined) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

axiosApi.interceptors.response.use((response: any) => {
  if (response.data.status && response.data.status === 401) {
    //handleInvalidToken();
  }
  return response;
}, function (error: any) {
  console.log("Axios error", error);
  if (!error.response) {

    // handleInvalidToken();
    return {
      data: {
        data: "",
        message:
          "There is some error while processing this request. Please try again after some time.",
        status: 500,
      },
    };
  }
  else {
    if (error.response.status === 500) {
      return {
        data: {
          data: "",
          message:
            "There is some error while processing this request. Please try again after some time.",
          status: 500,
        },
      };
    }

  }
  // Any status codes outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});


const objectToQuery = (obj: any) => {
  return (obj) ? Object.entries(obj).map(([k, v]: any) => `${k}=${encodeURIComponent(v)}`).join("&") : '';
}
const apiUrl = process.env.NEXT_PUBLIC_API_URL;

export { objectToQuery, axiosApi, apiUrl };