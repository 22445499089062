// "use client";

// import React from "react";
// import { Field, ErrorMessage } from "formik";
// import { PhoneInput } from "react-international-phone";
// import "react-international-phone/style.css";

// interface PhoneFieldProps {
//   name: string;
//   label?: string;
//   defaultCountry?: string
// }

// export const PhoneField: React.FC<PhoneFieldProps> = ({ name, label, ...rest }) => {
//   return (
//     <div className="form-input form-input-phone">
//       {label && <label htmlFor={name} className="lh-1 text-12 text-light-1">
//         {label}
//       </label>}
//       <Field name={name}>
//         {({ field, form }: any) => (
//           <PhoneInput
//           value={field?.value || ""}
//           onChange={(phone: string, { country, inputValue}: { country: any, inputValue: any}) => {
//             form.setFieldValue(name, phone);
//             form.setFieldValue(`${name}_country_code`, country?.iso2);
//             form.setFieldValue(`phone_code`, `+${country?.dialCode}`);
//           }}
//           {...rest}
//         />
//         )}
//       </Field>
//       <ErrorMessage name={name} component="div" className="error-message" />
//     </div>
//   );
// };

"use client";

import React from "react";
import { Field, ErrorMessage, FieldProps } from "formik";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneFieldProps {
  name: string;
  label?: string;
  defaultCountry?: string;
  onValueChange?: (
    phone: string,
    details: { country: any; inputValue: string }
  ) => void;
}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  name,
  label,
  onValueChange,
  ...rest
}) => {
  const handleChange = (
    field: FieldProps["field"],
    form: FieldProps["form"],
    phone: string,
    details: { country: any; inputValue: string }
  ) => {
    form.setFieldValue(name, phone);
    form.setFieldValue(`${name}_country_code`, details?.country?.iso2);
    form.setFieldValue(`phone_code`, `+${details?.country?.dialCode}`);

    if (onValueChange) {
      onValueChange(phone, details); // Trigger custom handler
    }
  };

  return (
    <div className="form-input form-input-phone">
      {label && (
        <label htmlFor={name} className="lh-1 text-12 text-light-1">
          {label}
        </label>
      )}
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <PhoneInput
            value={field.value || ""}
            onChange={(phone, details) => handleChange(field, form, phone, details)}
            {...rest}
          />
        )}
      </Field>
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};
