import { createAppSlice } from "@/lib/createAppSlice";
import { getFetchApi, postApi } from "@/utils/http";
import type { PayloadAction } from "@reduxjs/toolkit";
import { currencies } from "@/data/currencies";
import { showToast } from '@/app/CustomToastProvider';

interface Country {
  name: string;
  code: string;
}
export interface HomeSliceState {
  payload: any;
  status: "idle" | "loading" | "failed";
  loading: boolean;
  places: any;
  properties: any;
  userCurrency: any;
  userCurrentLocation: any;
  userCurrentCountry: string | null;
  countries: Country[],
  countriesLoading: boolean,
  contactUsLoading: boolean,
  emailSubscribeLoading: boolean,
  grabDealLoading: boolean,
  propertiesLoading: boolean
}

const initialState: HomeSliceState = {
  payload: {},
  status: "idle",
  loading: false,
  places: [],
  properties: [],
  userCurrency: currencies[0],
  userCurrentLocation: null,
  userCurrentCountry: currencies[0]?.nationality_code,
  countries: [],
  countriesLoading: false,
  contactUsLoading: false,
  emailSubscribeLoading: false,
  grabDealLoading: false,
  propertiesLoading: false
};

// If you are not using async thunks you can use the standalone `createSlice`.
export const homeSlice = createAppSlice({
  name: "home",
  initialState,
  reducers: (create) => ({
    saveUserCurrentLocation: create.reducer((state, { payload }: PayloadAction<GeolocationPosition>) => {
      state.userCurrentLocation = payload;
    }),
    saveUserCurrentCountry: create.reducer((state, { payload }: PayloadAction<string>) => {
      state.userCurrentCountry = payload;
    }),
    saveUserCurrency: create.reducer((state, { payload }: PayloadAction<object>) => {
      state.userCurrency = payload;
      
    }),
    getPlaces: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          console.log("E Is", e)
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        fulfilled: (state, { payload }) => {
          console.log("API response is", payload?.data)
          state.loading = false;
          state.places = payload?.data?.data?.results;
          // showToast('Action successful!', 'success');
        },
        rejected: (state) => {
          // console.log("Error", state)
          state.loading = false;
        },
      }
    ),
    getAllCountries: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          console.log("E Is", e)
        }
      },
      {
        pending: (state) => {
          state.countriesLoading = true;
        },
        fulfilled: (state, { payload }) => {
          state.countriesLoading = false;
          state.countries = payload?.data || [];
        },
        rejected: (state) => {
          // console.log("Error", state)
          state.countriesLoading = false;
        },
      }
    ),
    contactUs: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          console.log("E Is", e)
        }
      },
      {
        pending: (state) => {
          state.contactUsLoading = true;
        },
        fulfilled: (state, { payload }) => {
          const { status, message,  data } = payload;
          state.contactUsLoading = false;
          if(status == 200){
            showToast(message, "success");
          }
          else{
            showToast(message, "danger");
          }
        },
        rejected: (state) => {
          // console.log("Error", state)
          showToast("Internal Server Error","danger");
          state.contactUsLoading = false;
        },
      }
    ),
    emailSubscribe: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          console.log("E Is", e)
        }
      },
      {
        pending: (state) => {
          state.emailSubscribeLoading = true;
        },
        fulfilled: (state, { payload }) => {
          state.emailSubscribeLoading = false;
          const { status, message,  data } = payload;
          if(status == 200){
            showToast(message, "success");
          }
          else{
            showToast(message, "danger");
          }
        },
        rejected: (state) => {
          // console.log("Error", state)
          state.emailSubscribeLoading = false;
          showToast("Internal Server Error","danger");
        },
      }
    ),
    grabDeal: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          console.log("E Is", e)
        }
      },
      {
        pending: (state) => {
          state.grabDealLoading = true;
        },
        fulfilled: (state, { payload }) => {
          const { status, message,  data } = payload;
          state.grabDealLoading = false;
          if(status == 200){
            showToast(message, "success");
          }
          else{
            showToast(message, "danger");
          }
        },
        rejected: (state) => {
          showToast("Internal Server Error","danger");
          state.grabDealLoading = false;
        },
      }
    ),
    getProperties: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          console.log("E Is", e)
        }
      },
      {
        pending: (state) => {
          state.propertiesLoading = true;
        },
        fulfilled: (state, { payload }) => {
          // console.log("Payload", payload)
          state.propertiesLoading = false;
          state.properties = payload?.data?.data?.results;
          // showToast('Action successful!', 'success');
        },
        rejected: (state) => {
          // console.log("Error", state)
          state.propertiesLoading = false;
        },
      }
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    getSearchPayload: (_) => _?.payload,
    getUserCurrency: (_) => _?.userCurrency,
    places: (_) => _?.places,
    getUserCurrentLocation: (_) => _?.userCurrentLocation,
    getUserCurrentCountry: (_) => _?.userCurrentCountry,
    getCountries: (_) => _?.countries,
    getCountriesLoading: (_) => _?.countriesLoading,
    getPlacesLoading: (_) => _?.loading,
    getCoutactUsLoading: (_) => _?.contactUsLoading,
    getEmailSubscribeLoading: (_) => _?.emailSubscribeLoading,
    getGrabDealLoading: (_) => _?.grabDealLoading,
    properties : (_) => _?.properties,
    getPropertiesLoading : (_) => _?.propertiesLoading
  },
});

// Action creators are generated for each case reducer function.
export const { saveUserCurrentLocation, saveUserCurrentCountry, getPlaces, saveUserCurrency, getAllCountries, contactUs, emailSubscribe, grabDeal, getProperties } = homeSlice?.actions;

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const { getUserCurrentLocation, getSearchPayload, getUserCurrency, places, getUserCurrentCountry, getCountries, getCountriesLoading, getPlacesLoading, getCoutactUsLoading, getEmailSubscribeLoading, getGrabDealLoading, properties, getPropertiesLoading } = homeSlice?.selectors;
