"use client";
import { useState } from "react";
import Image from "next/image";
import { Spinner } from "reactstrap";
import { BloomFilter } from "next/dist/shared/lib/bloom-filter";
import DEFAULT_IMG from "@/img/img-default.png";
import "./ImageWithLoader.css";
import { ArrowClockwise } from "react-bootstrap-icons";
import styles from "@/components/ui/Loader.module.scss";

interface ImageWithLoaderProps {
  className?: string;
  parentClassName?: string;
  src: any;
  alt: string;
  width?: number;
  height?: number;
  sizes?: string;
  style?: object;
  showLoader?: boolean;
  priority?: boolean;
  loading?: any;
  quality?: any;
  title?: string;
  caption?: string;
  fill?: boolean
  layout?: string;
  unoptimized?: boolean;
}

export const ImageWithLoader: React.FC<ImageWithLoaderProps> = ({
  parentClassName,
  src,
  alt,
  width,
  height,
  showLoader,
  caption,
  loading,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(
    showLoader == false ? false : true
  );
  const [imageLoadError, setImageLoadError] = useState<boolean>(false);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const handleImageErrorLoad = () => {
    setImageLoadError(true);
  };

  return (
    <>
      <figure className={`positive-relative ${parentClassName ?? ""}`}>
      {isLoading && <div className="loading-indicator"> <ArrowClockwise size={20} className={styles?.spinner} /></div>}
        <Image
          loading={loading || undefined}
          src={imageLoadError ? DEFAULT_IMG : src}
          alt={alt}
          width={width}
          height={height}
          onLoad={handleImageLoad}
          onError={handleImageErrorLoad}
          {...rest}
        />
        {caption && (
          <figcaption style={{ color: "#6B6B6B", textAlign: "center", fontSize: "13px",  padding: "10px 10px" }}>
            {caption}
          </figcaption>
        )}
      </figure>
    </>
  );
};
