"use client";
import modal from "bootstrap/js/dist/modal";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";

interface ToastContextType {
  showToast: (
    message: string,
    type?: "success" | "danger" | "info" | "warning"
  ) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a CustomToastProvider");
  }
  return context;
};

let toastInstance: ToastContextType | null = null;

export const initializeToast = (instance: ToastContextType) => {
  toastInstance = instance;
};

export const showToast = (
  message: any,
  type: "success" | "danger" | "info" | "warning" = "info"
) => {
  if (toastInstance) {
    toastInstance.showToast(message, type);
  }
};

interface CustomToastProviderProps {
  children: ReactNode;
}

export const CustomToastProvider: React.FC<CustomToastProviderProps> = ({
  children,
}) => {
  const toggle = () => setIsVisible(!isVisible);
  const [toastClass, setToastClass] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");
  const [toastType, setToastType] = useState<
    "success" | "error" | "danger" | "info" | "warning"
  >();

  const showToastInternal = (message: any, type: any) => {
    console.log("showToastInternal", message, type);
    if(message){
      setMessage(message);
    }
   
    setToastType(type == "danger" ? "error" : type);
    setIsVisible(true);
    // setTimeout(() => setIsVisible(false), 3000);
  };

  const contextValue = { showToast: showToastInternal };

  initializeToast(contextValue);

  useEffect(() => {
    if (toastType) {
      setToastClass(toastType == "error" ? "text-red-2" : "text-green-2");
    }
  }, [toastType]);
  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <div style={{ position: "fixed", top: "50%", left: "50%", zIndex: 9999 }}>
        {/* <Toast isOpen={isVisible} color={toastType}>
          <ToastHeader icon={toastType} toggle={function noRefCheck(){}} >{toastType.toUpperCase()}</ToastHeader>
          <ToastBody>{message}</ToastBody>
        </Toast> */}
        <Modal isOpen={isVisible} toggle={toggle} fade={false}>
          <ModalHeader toggle={toggle} className={toastClass}>
            {toastType?.toUpperCase()}
          </ModalHeader>
          <ModalBody className={toastClass}>{message ? message : ""}</ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={toggle}
              className="button py-20 -dark-1 bg-blue-1 text-white w-30"
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </ToastContext.Provider>
  );
};
