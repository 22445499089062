// "use client";

// import React from 'react';
// import { Field, ErrorMessage } from 'formik';

// interface InputFieldProps {
//   name: string;
//   label?: string;
//   type?: string;
//   parentClassName?: string;
//   placeholder?: string;
//   disabled?: boolean;
//   showError?: boolean;
// }

// export const InputField: React.FC<InputFieldProps> = ({ name, label, type = 'text', parentClassName = '', ...rest }) => {
//   return (
//     <div className={`form-input ${parentClassName}`}>
//      {label && <label htmlFor={name} className="lh-1 text-16 text-light-1">{label}</label>}
//       <Field id={name} name={name} type={type}{...rest} />
//       <ErrorMessage name={name} component="div" className="error-message" />
//     </div>
//   );
// };

"use client";

import React from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import DatePicker from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';

interface InputFieldProps {
  id?: string;
  name: string;
  label?: string;
  type?: string;
  parentClassName?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  showError?: boolean;
  onValueChange?: (value: string) => void; // Custom handler for value change
  style?: any
  birthday?: boolean
}

export const InputField: React.FC<InputFieldProps> = ({
  id,
  name,
  label,
  type = 'text',
  parentClassName = '',
  onValueChange,
  birthday,
  ...rest
}) => {
  const handleChange = (field: FieldProps['field'], event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event); // Formik's default change handler
    if (onValueChange) {
      onValueChange(event.target.value); // Custom handler
    }
  };

  return (
    <div className={`form-input ${parentClassName}`}>
      {label && <label htmlFor={id || name} className="lh-1 text-12 text-light-1">{label}</label>}
      <Field name={name}>
        {({ field }: FieldProps) => (
          <>
          <input
            id={id || name}
            type={type}
            {...field} // Pass Formik field props
            onChange={(event) => handleChange(field, event)} // Handle both formik and custom change
            {...rest}
          />
          
          </>
        )}
      </Field>
     
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};
