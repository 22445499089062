"use client";
import "react-toastify/dist/ReactToastify.css"; 
import { ToastContainer } from "react-toastify";

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) =>  {
  return (
    <>
      {children}
      <ToastContainer
        key={1}
        limit={1}
        position="top-right"
        autoClose={30000}
        hideProgressBar={false}
        newestOnTop={true}
        draggable={false}
        closeOnClick
        pauseOnHover
        />
    </>
  );
}