"use client";

import React from 'react';
import { Field, ErrorMessage } from 'formik';

interface SelectFieldProps {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
  parentClassName?: string;
}

export const SelectField: React.FC<SelectFieldProps> = ({ name, options, label, parentClassName = '' }) => (
  <div className={`form-input ${parentClassName}`}>
    {label && <label htmlFor={name} className="lh-1 text-12 text-light-1">{label}</label>}
    <Field as="select" id={name} name={name} className="form-control">
      <option value="">Select {label}</option>
      {options.map((option) => (
        <option key={option?.value} value={option?.value}>{option?.label}</option>
      ))}
    </Field>
    <ErrorMessage name={name} component="div" className="text-danger" />
  </div>
);
