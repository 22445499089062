import { createAppSlice } from "@/lib/createAppSlice";
import type { AppThunk } from "@/lib/store";
import { getApi, getFetchApi, postApi } from "@/utils/http";
import type { PayloadAction } from "@reduxjs/toolkit";
import { signIn } from "next-auth/react";
import { toast } from "react-toastify";
import NavigationService from "@/utils/NavigationService"; // Adjust the path
import { showToast } from "@/app/CustomToastProvider";
import {
  saveUserCurrentCountry,
  saveUserCurrency,
} from "@/lib/features/home/homeSlice";
import { currencies } from "@/data/currencies";

export interface AuthSliceState {
  loginLoading: boolean;
  signupLoading: boolean;
  forgotLoading: boolean;
  resetLoading: boolean;
  user: any;
  authToken: string | null;
  error: string | null;
  forgotEmails: { [key: string]: any };
  referralLoading: boolean;
}

const initialState: AuthSliceState = {
  loginLoading: false,
  signupLoading: false,
  user: {},
  authToken: null,
  error: null,
  forgotEmails: {},
  forgotLoading: false,
  resetLoading: false,
  referralLoading: false,
};

export const authSlice = createAppSlice({
  name: "auth",
  initialState,
  reducers: (create) => ({
    saveAuthData: create.reducer((state, { payload }: PayloadAction<any>) => {
      state.user = payload;
      state.authToken = payload?.auth_token;
    }),
    signUp: create.asyncThunk(
      async (payload: { payload: any }, { dispatch }) => {
        const user = payload.payload?.user;
        if (user) {
          const currencyData: any = user?.country_code && currencies?.filter(
            (item) => item.nationality_code === user?.country_code
          );
          const userCurrency = currencyData?.length > 0 && currencyData[0] || currencies[0];
          const userCountry =
            user?.country_code || currencies[0]?.nationality_code;
          dispatch(saveUserCurrentCountry(userCountry));
          dispatch(saveUserCurrency(userCurrency));
        }
        return payload.payload;
      },
      {
        pending: (state) => {
          state.signupLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, dispatch }: any) => {
          state.signupLoading = false;
          const { status, message, data } = payload;
          if (status == 200) {
            state.user = data;
            state.authToken = data?.auth_token;
            // Dispatch homeSlice actions
            const currencyData: any = currencies?.filter(
              (item) => item.nationality_code == data?.country_code
            );
            dispatch(
              saveUserCurrentCountry(
                data?.country_code || currencies[0]?.nationality_code
              )
            );
            dispatch(saveUserCurrency(currencyData || currencies[0]));
          }
          //   console.log("fulfilled", payload);
        },
        rejected: (state, action) => {
          state.signupLoading = false;
          state.error = action.error?.message || "Failed to fetch hotels";
          showToast("Internal Server Error"), "danger";
        },
      }
    ),
    login: create.asyncThunk(
      async (payload: { payload: any }, { dispatch }) => {
        const user = payload.payload?.user;
        if (user) {
          const currencyData: any = user?.country_code && currencies?.filter(
            (item) => item.nationality_code === user?.country_code
          );
          const userCurrency = currencyData?.length > 0 && currencyData[0] || currencies[0];
          const userCountry =
            user?.country_code || currencies[0]?.nationality_code;
          dispatch(saveUserCurrentCountry(userCountry));
          dispatch(saveUserCurrency(userCurrency));
        }
        return payload.payload;
      },
      {
        pending: (state) => {
          state.loginLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, dispatch }: any) => {
          state.loginLoading = false;
        },
        rejected: (state, action) => {
          state.loginLoading = false;
          state.error = action.error?.message || "Failed to fetch hotels";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
    forgotPassword: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.forgotLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          const { email } = meta?.arg?.payload;
          console.log("Forgot Password", payload);
          state.forgotLoading = false;
          const { status, message, data } = payload;
          if (status == 200) {
            state.forgotEmails[email] = data;
            showToast(message, "success");
            NavigationService.navigate(`/reset-password?email=${email}`);
          } else {
            showToast(message, "danger");
          }
        },
        rejected: (state, action) => {
          state.forgotLoading = false;
          state.error = action.error?.message || "Failed to fetch hotels";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
    resetPassword: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.resetLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          const { email } = meta?.arg?.payload;
          // console.log("Reset Password", payload);
          state.resetLoading = false;
          const { status, message, data } = payload;
          if (status == 200) {
            if (state.forgotEmails[email]) {
              delete state.forgotEmails[email];
            }
            showToast(message, "success");
            NavigationService.navigate(`/login`);
          } else {
            showToast(message, "danger");
          }
        },
        rejected: (state, action) => {
          state.resetLoading = false;
          state.error = action.error?.message || "Failed to fetch hotels";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
    getReferralCodeDetail: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.referralLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.referralLoading = false;
          const { status, message, data } = payload;
          if (status == 200) {
          } else {
            showToast(message, "danger");
          }
        },
        rejected: (state, action) => {
          state.referralLoading = false;
          state.error = action.error?.message || "Failed to fetch hotels";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
  }),
  selectors: {
    getUser: (_) => _?.user,
    getForgotPasswordLoading: (_) => _?.forgotLoading,
    getResetPasswordLoading: (_) => _?.resetLoading,
    gerReferralLoading: (_) => _?.referralLoading,
  },
});

export const {
  signUp,
  login,
  saveAuthData,
  forgotPassword,
  resetPassword,
  getReferralCodeDetail,
} = authSlice?.actions;
export const {
  getUser,
  getForgotPasswordLoading,
  getResetPasswordLoading,
  gerReferralLoading,
} = authSlice.selectors;
