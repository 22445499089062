import React from 'react';
import { Field, ErrorMessage } from 'formik';

interface TextareaFieldProps {
  id?:string;
  name: string;
  label?: string;
  rows?: number;
  parentClassName?: string;
  className?: string;
}

export const TextareaField: React.FC<TextareaFieldProps> = ({
  id,
  name,
  label,
  rows = 3,
  parentClassName = '',
  className = ''
}) => (
  <div className={`form-textarea ${parentClassName ?? ''}`}>
    {label && <label htmlFor={id || name} className={`lh-1 text-12 text-light-1`}>{label}</label>}
    <Field as="textarea" id={id || name} name={name} rows={rows} className={`form-control ${className ?? ""}`} />
    <ErrorMessage name={name} component="div" className="error-message-1" />
  </div>
);
