import { useRouter } from 'next/navigation';

class NavigationService {
  private static instance: NavigationService;
  private router: any;

  private constructor(router: any) {
    this.router = router;
  }

  // Method to initialize the router instance
  public static initialize(router: any) {
    if (!this.instance) {
      this.instance = new NavigationService(router);
    }
  }

  // Method to navigate to a new route
  public static navigate(name: string, options?: any) {
    if (this.instance && this.instance.router) {
      this.instance.router.push(name, options);
    } else {
      console.error("NavigationService is not initialized");
    }
  }
}

export default NavigationService;
