import React, { useRef, useEffect, useState, useMemo } from "react";
import {
  APIProvider,
  useApiLoadingStatus,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton CSS
import "./AddressInput.css";
interface AddressInputProps {
  name: string;
  label?: string;
  onSelect: (addressData: {
    city?: string;
    state?: string;
    country?: string;
    zip_code?: string;
    streetAddress?: string;
    latitude?: number | null;
    longitude?: number | null;
  }) => void;
  values?: any;
  setFieldValue?: any;
}

interface PlaceDetails {
  address: string;
  city: string;
  state: string;
  country: string;
  lat: number;
  lng: number;
}

export const AddressInput: React.FC<AddressInputProps> = ({
  name,
  label,
  onSelect,
  values,
  setFieldValue,
}) => {
  const placesLib = useMapsLibrary("places");
  const [inputValue, setInputValue] = useState("");
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const [loading, setLoading] = useState(false); // Loader state

  const [selectedPlaceDetails, setSelectedPlaceDetails] =
    useState<PlaceDetails | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);


  useEffect(() => {
    // console.log("values", values);
    setInputValue(values?.address_line_1);
    if(values?.address_line_1){
      fetchPredictions(values?.address_line_1);
    }
  }, [values]);
  // Initialize AutocompleteService
  useEffect(() => {
    if (placesLib && !autocompleteServiceRef.current) {
      autocompleteServiceRef.current = new placesLib.AutocompleteService();
    }
  }, [placesLib]);

  const fetchPredictions = (input: string) => {
    if (!autocompleteServiceRef.current || !input) {
      setPredictions([]);
      return;
    }
    setLoading(true); // Show loader
    const request: google.maps.places.AutocompletionRequest = { input };

    autocompleteServiceRef.current.getPlacePredictions(
      request,
      (results, status) => {
        console.log("results", results, status);
        setLoading(false); // Hide loader
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          setPredictions(results);
        } else {
          setPredictions([]);
          console.error("Autocomplete failed:", status);
        }
      }
    );
  };

  const fetchPlaceDetails = (placeId: string) => {
    if (!placesLib) return;

    const placesService = new placesLib.PlacesService(
      document.createElement("div")
    );

    placesService.getDetails({ placeId }, (result, status) => {
      setLoading(false); // Hide loader
      if (status === google.maps.places.PlacesServiceStatus.OK && result) {
        const lat = result.geometry?.location?.lat() || 0;
        const lng = result.geometry?.location?.lng() || 0;

        const addressComponents = result.address_components || [];
        const getAddressComponent = (type: string) =>
          addressComponents.find(
            (component: any) => component.types.includes(type) || ""
          );
        const data: any = {
          address: result?.formatted_address || "",
          city: getAddressComponent("locality")?.long_name,
          state: getAddressComponent("administrative_area_level_1")?.long_name,
          country: getAddressComponent("country")?.short_name,
          zip_code: getAddressComponent("postal_code")?.long_name,
          lat,
          lng,
        };
        setSelectedPlaceDetails(data);
        onSelect(data);
        setInputValue(result?.formatted_address || "");
        // setFieldValue('address_line_1', result?.formatted_address || "");
        setShowDropdown(false);
      } else {
        console.error("Failed to fetch place details:", status);
      }
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    // setFieldValue('address_line_1', value);
    console.log("handleInputChange", value);
    if (value) {
      setShowDropdown(true);
      fetchPredictions(value);
    } else {
      setShowDropdown(false);
    }
  };

  const handleInputFocus = () => {
    if (predictions.length > 0) {
      setShowDropdown(true);
    }
  };

  const handlePredictionClick = (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    setLoading(true); // Show loader
    fetchPlaceDetails(prediction?.place_id);
    toggleDropdown();
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const LocationSearchSkeleton = () => (
    <>
      {Array?.from({ length: 5 }).map((_, index) => (
        <li
          className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option mb-1"
          key={index}
        >
          <div className="d-flex">
            <div className="icon-location-2 text-light-1 text-20 pt-4" />
            <div className="ml-10">
              <div className="text-15 lh-12 fw-500 js-search-option-target">
                <Skeleton width={150} />
              </div>
              <div className="text-14 lh-12 text-light-1 mt-5">
                <Skeleton width={100} />
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="col-12"
      >
        <div className="form-input w-100">
          <DropdownToggle caret={false} tag="div" className="dropdown-toggle">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              placeholder="Search for a city"
              // className="border p-2 w-full"
            />

           
          </DropdownToggle>
        </div>
        {label && (
              <label className="lh-1 text-12 text-light-1 label">{label}</label>
            )}
        {/* {JSON.stringify(predictions)} */}
        <DropdownMenu className="shadow-2" autoClose="outside">
          <div className="bg-white px-20 py-20 sm:px-0 sm:py-15 rounded-4 scrollable-container">
            <ul className="y-gap-5 js-results">
              {/* {!loading && preOptions?.length == 0 && <li  className={`-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option mb-1`}>Type or search</li>} */}
              {loading ? (
                <LocationSearchSkeleton />
              ) : (
                predictions?.length > 0 &&
                predictions?.map((item: any) => (
                  <li
                    className={`-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option mb-1`}
                    key={item?.code}
                    role="button"
                    onClick={() => handlePredictionClick(item)}
                  >
                    <div className="d-flex">
                      <div className="ml-10">
                        <div className="text-15 lh-12 fw-500">
                          {item?.description}
                        </div>
                        {/* <div className="text-14 lh-12 text-light-1 mt-5">
                          {item?.description}
                        </div> */}
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </DropdownMenu>

        {/* {showDropdown && predictions.length > 0 && (
          <ul
            className="absolute top-full left-0 w-full bg-white border shadow-lg z-10000 max-h-60 overflow-auto"
            style={{ zIndex: 10000 }}
          >
            {predictions.map((prediction) => (
              <li
                key={prediction.place_id}
                onClick={() => handlePredictionClick(prediction)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              >
                {prediction.description}
              </li>
            ))}
          </ul>
        )} */}
      </Dropdown>
    </>
  );
};
