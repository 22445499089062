import React from "react";
import { Field, ErrorMessage, useField } from "formik";

interface RadioButtonFieldProps {
  name: string;
  label: string;
  options: Array<{ value: string | number; label: string }>;
  required?: boolean;
}

export const RadioButtonField: React.FC<RadioButtonFieldProps> = ({
  name,
  label,
  options,
  required = false,
}) => {
  // useField gives you field-level info and helpers
  const [field, meta] = useField(name);

  return (
    <>
      <label className="lh-1 text-12 text-light-1">{label}</label>
      <div className="form-input">
        {options?.map((option) => (
          <label key={option.value} style={{ marginRight: "1rem" }}>
            <Field
              type="radio"
              name={name}
              value={option?.value?.toString()} // Ensure the value is a string for comparison
            //   className={
            //     meta.touched && meta.error ? "radio-error" : "radio-field"
            //   }
            />
            {option?.label}
          </label>
        ))}
        <ErrorMessage name={name}>
          {(msg) => <span className="error-message">{msg}</span>}
        </ErrorMessage>
      </div>
    </>
  );
};
