import React, { useState } from "react";
import { Field, ErrorMessage, useField } from "formik";
import { Eye, EyeSlash } from "react-bootstrap-icons";

interface PasswordFieldProps {
  name: string;
  label: string;
  required?: boolean;
  autoComplete?: string;
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  label,
  required = false,
  autoComplete,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Get field meta from Formik's useField hook
  const [field, meta] = useField(name);

  return (
      <div className="form-input">
           <label className="lh-1 text-12 text-light-1">{label}</label>
        <Field
          // className={meta.touched && meta.error ? "input-error" : ""}
          autoComplete={autoComplete || "new-password"}
          type={showPassword ? "text" : "password"}
          {...field}
          required={required}
          style={{ paddingRight: "2.5rem" }} // Adjust padding to fit the icon
        />
        <span
          onClick={toggleShowPassword}
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
        >
          {showPassword ? <Eye size={18} /> : <EyeSlash size={18} />}
        </span>
     
        <ErrorMessage name={name}>
          {(msg) => <span className="error-message">{msg}</span>}
        </ErrorMessage>
      </div>
  );
};
