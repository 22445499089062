import { showToast } from "@/app/CustomToastProvider";
import { createAppSlice } from "@/lib/createAppSlice";
import { getFetchApi, postApi, putApi } from "@/utils/http";
import {
  signOut,
  getSession as getClientSession,
} from "next-auth/react"; // Use client-side getSession
export interface ProfileSliceState {
  logoutLoading: boolean;
  changePasswordLoading: boolean;
  invitationLoading: boolean;
  profileLoading: boolean;
  error: string | null;
  myProfile: any;
}

const initialState: ProfileSliceState = {
  logoutLoading: false,
  changePasswordLoading: false,
  invitationLoading: false,
  profileLoading: false,
  error: null,
  myProfile: null,
};

export const profileSlice = createAppSlice({
  name: "profile",
  initialState,
  reducers: (create) => ({
    logout: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.logoutLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.logoutLoading = false;
          console.log("logout payload", payload);

          if (payload?.status && payload?.status === 200) {
            setTimeout(() => {
              signOut({ callbackUrl: "/login" });
            }, 1000);
          }
          else{
            setTimeout(() => {
              signOut({ callbackUrl: "/login" });
            }, 1000);
          }
        
          //
        },
        rejected: (state, action) => {
          state.logoutLoading = false;
          state.error = action.error?.message || "Failed to fetch data";
          showToast("Failed to fetch data", "danger");
        },
      }
    ),
    changePassword: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await putApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.changePasswordLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.changePasswordLoading = false;
          const { status, message, data } = payload;
          if (status == 200) {
            showToast(message, "success");
          } else {
            showToast(message, "danger");
          }
        },
        rejected: (state, action) => {
          state.changePasswordLoading = false;
          state.error = action.error?.message || "Failed to fetch data";
          showToast("Failed to fetch data", "danger");
        },
      }
    ),
    inviteFriend: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.invitationLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.invitationLoading = false;
          const { status, message, data } = payload;
          if (status == 200) {
            showToast(message, "success");
          } else {
            showToast(message, "danger");
          }
        },
        rejected: (state, action) => {
          state.invitationLoading = false;
          state.error = action.error?.message || "Failed to fetch data";
          showToast("Failed to fetch data", "danger");
        },
      }
    ),
    updateProfile: create.asyncThunk(
      async (payload: any, { dispatch }) => {
        try {
          const response = await putApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.profileLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.profileLoading = false;

          const { status, message, data } = payload;
          if (status === 200) {
            showToast(message, "success");
            // Update the myProfile state with the new data
            state.myProfile = { ...state.myProfile, ...data };
          } else {
            showToast(message, "danger");
          }
        },
        rejected: (state, action) => {
          state.profileLoading = false;
          state.error = action.error?.message || "Failed to fetch data";
          showToast("Failed to fetch data", "danger");
        },
      }
    ),
    getMyProfile: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.profileLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.profileLoading = false;
          const { status, message, data } = payload;
          if (status === 200) {
            state.myProfile = data;
          }
        },
        rejected: (state, action) => {
          state.profileLoading = false;
          state.error = action.error?.message || "Failed to fetch data";
          showToast("Failed to fetch data", "danger");
        },
      }
    ),
  }),
  selectors: {
    error: (_) => _?.error,
    getlogoutLoading: (_) => _?.logoutLoading,
    getChangePasswordLoading: (_) => _?.changePasswordLoading,
    getInvitationLoading: (_) => _?.invitationLoading,
    getProfileLoading: (_) => _?.profileLoading,
    profile: (_) => _?.myProfile,
  },
});

export const {
  logout,
  changePassword,
  inviteFriend,
  updateProfile,
  getMyProfile,
} = profileSlice.actions;
export const {
  getlogoutLoading,
  getChangePasswordLoading,
  getInvitationLoading,
  getProfileLoading,
  profile,
} = profileSlice.selectors;
